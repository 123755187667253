<template>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 635.15 739.25">
  <defs>
    <filter id="outer-glow-1" filterUnits="userSpaceOnUse">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur result="blur" stdDeviation="5"/>
      <feFlood flood-color="#1bcccc" flood-opacity=".75"/>
      <feComposite in2="blur" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="outer-glow-2" filterUnits="userSpaceOnUse">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur result="blur-2" stdDeviation="5"/>
      <feFlood flood-color="#ff9f1c" flood-opacity=".75"/>
      <feComposite in2="blur-2" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="outer-glow-3" filterUnits="userSpaceOnUse">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur result="blur-3" stdDeviation="5"/>
      <feFlood flood-color="#7c386d" flood-opacity=".75"/>
      <feComposite in2="blur-3" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="outer-glow-4" filterUnits="userSpaceOnUse">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur result="blur-4" stdDeviation="5"/>
      <feFlood flood-color="#ea476e" flood-opacity=".75"/>
      <feComposite in2="blur-4" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Layer_2-2" data-name="Layer 2">
    <g>
      <rect class="cls-10" x=".91" y=".25" width="634" height="738" rx="38" ry="38"/>
      <g class="cls-7">
        <path class="cls-2" d="M550.82,661.02l-4.76-.04c-.74-3.96-2.65-7.65-5.54-10.59-3.85-3.95-9.05-6.18-14.61-6.27h-.17c-5.5-.04-10.68,2.06-14.63,5.91-4.01,3.9-6.23,9.11-6.27,14.66-.13,11.51,9.07,20.94,20.57,21.08h.11c5.67,.03,10.91-2.23,14.69-5.97,3.22-3.12,5.38-7.2,6.03-11.8,.03-.17,.06-.34,.08-.51l4.5,.05,83.82,.39v-6.52l-83.82-.39Zm-10.25,6.88c-1.39,7.01-7.66,12.3-15.01,12.25h-.11c-8.36-.11-15.11-7-15-15.36,.03-4.1,1.68-7.91,4.56-10.75,2.88-2.79,6.71-4.33,10.75-4.31h.06c4.04,.03,7.85,1.68,10.69,4.56,2.17,2.26,3.61,5.02,4.16,8,.1,.9,.21,1.86,.2,2.81,0,.95-.12,1.91-.3,2.8Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-12" d="M.84,605.41v6.52s83.82-.39,83.82-.39l4.5-.05c.02,.17,.05,.34,.08,.51,.65,4.6,2.81,8.68,6.03,11.8,3.78,3.74,9.02,6,14.69,5.97h.11c11.5-.14,20.7-9.57,20.57-21.08-.04-5.55-2.26-10.76-6.27-14.66-3.95-3.85-9.13-5.95-14.63-5.91h-.17c-5.56,.09-10.76,2.32-14.61,6.27-2.89,2.94-4.8,6.63-5.54,10.59l-4.76,.04-83.82,.39Zm93.77,3.69c0-.95,.1-1.91,.2-2.81,.55-2.98,1.99-5.74,4.16-8,2.84-2.88,6.65-4.53,10.69-4.56h.06c4.04-.02,7.87,1.52,10.75,4.31,2.88,2.84,4.53,6.65,4.56,10.75,.11,8.36-6.64,15.25-15,15.36h-.11c-7.35,.05-13.62-5.24-15.01-12.25-.18-.89-.3-1.85-.3-2.8Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-12" d="M.84,438.41v6.52s83.82-.39,83.82-.39l4.5-.05c.02,.17,.05,.34,.08,.51,.65,4.6,2.81,8.68,6.03,11.8,3.78,3.74,9.02,6,14.69,5.97h.11c11.5-.14,20.7-9.57,20.57-21.08-.04-5.55-2.26-10.76-6.27-14.66-3.95-3.85-9.13-5.95-14.63-5.91h-.17c-5.56,.09-10.76,2.32-14.61,6.27-2.89,2.94-4.8,6.63-5.54,10.59l-4.76,.04-83.82,.39Zm93.77,3.69c0-.95,.1-1.91,.2-2.81,.55-2.98,1.99-5.74,4.16-8,2.84-2.88,6.65-4.53,10.69-4.56h.06c4.04-.02,7.87,1.52,10.75,4.31,2.88,2.84,4.53,6.65,4.56,10.75,.11,8.36-6.64,15.25-15,15.36h-.11c-7.35,.05-13.62-5.24-15.01-12.25-.18-.89-.3-1.85-.3-2.8Z"/>
      </g>
      <g class="cls-15">
        <path class="cls-11" d="M519.8,79.05l-.22-78.96-6.51-.09,.21,78.85c-10.02,1.25-17.84,9.59-18.26,19.85-.01,.28-.02,.56-.02,.84-.02,11.54,9.31,20.92,20.86,20.95,11.26,.02,20.47-8.87,20.93-20.03,.01-.27,.01-.55,.02-.83,.02-10.22-7.31-18.75-17.01-20.58Zm10.48,21.14c-.32,7.75-6.65,13.8-14.41,13.78-3.84,0-7.45-1.51-10.16-4.23-2.72-2.73-4.21-6.34-4.2-10.19l.02-.58c.32-7.74,6.65-13.79,14.41-13.78,3.84,.01,7.45,1.52,10.16,4.24,2.71,2.72,4.2,6.34,4.19,10.19v.57Z"/>
      </g>
      <g class="cls-1">
        <path class="cls-8" d="M556.3,167.62c-1.25-10.01-9.59-17.84-19.86-18.26-.27-.01-.55-.02-.83-.02-11.54-.02-20.92,9.31-20.95,20.86-.02,11.26,8.87,20.47,20.02,20.93,.28,.01,.56,.02,.84,.02,10.22,.02,18.75-7.31,20.58-17.01l78.96-.22,.09-6.51-78.85,.21Zm-10.58,12.82c-2.73,2.71-6.34,4.2-10.19,4.19h-.58c-7.74-.33-13.79-6.66-13.78-14.42,.01-3.84,1.52-7.45,4.24-10.16,2.73-2.72,6.34-4.2,10.19-4.2l.57,.02c7.75,.32,13.8,6.65,13.78,14.41,0,3.84-1.51,7.45-4.23,10.16Z"/>
      </g>
      <g class="cls-1">
        <path class="cls-13" d="M93.7,62.62c-5.01,0-9.95,2.13-13.67,5.75-3.84,3.79-6,8.85-6.09,14.25-.09,5.4,2,10.48,5.74,14.38,3.79,3.84,8.85,6,14.3,6.09h.11s.09,0,.14,0c5.29,0,10.32-2.04,14.08-5.74,3.84-3.79,6-8.85,6.09-14.3,.11-9.16-6.16-17.33-14.76-19.67-.71-.17-1.42-.34-2.18-.45-1.04-.22-2.02-.29-3.05-.29h-.22c-.16,0-.33,0-.49,0h0Zm.92,35.03c-.2,0-.41,0-.61-.01-3.93-.03-7.62-1.63-10.44-4.49-2.76-2.8-4.21-6.52-4.18-10.5,.03-3.93,1.63-7.62,4.49-10.39,2.74-2.65,6.35-4.16,10.23-4.19h.16c.76,.06,1.42,.07,2.24,.18,1.09,.17,2.07,.45,3.05,.89,5.55,2.16,9.44,7.59,9.4,13.75v.11c-.03,3.98-1.63,7.68-4.43,10.44-2.7,2.62-6.23,4.2-9.88,4.2h0Z"/>
      </g>
      <g class="cls-6">
        <path class="cls-13" d="M288.86,386.32h-.01c-18.78,0-34.76-14.67-36.36-33.38l-12.32-143.43c-1.72-20.06,13.2-37.77,33.25-39.5,19.98-1.75,37.8,13.5,39.5,33.26l12.33,143.43c1.72,20.06-13.2,37.77-33.25,39.5-1.04,.09-2.09,.14-3.12,.14Zm0-7.86h0c.81,0,1.62-.03,2.44-.11,15.74-1.35,27.45-15.26,26.11-30.99l-12.33-143.43c-1.34-15.5-15.28-27.46-30.99-26.1-15.74,1.35-27.44,15.26-26.09,30.99l12.32,143.43c1.26,14.68,13.8,26.19,28.54,26.19Z"/>
      </g>
      <g class="cls-14">
        <path class="cls-11" d="M369.32,386.32h0c-1.04,0-2.09-.04-3.14-.14-20.04-1.72-34.96-19.44-33.24-39.49l12.32-143.43c1.7-19.77,19.51-35,39.5-33.25,20.04,1.72,34.96,19.44,33.24,39.5l-12.32,143.43c-1.61,18.71-17.58,33.38-36.36,33.38Zm12.31-208.59c-14.74,0-27.27,11.51-28.54,26.2l-12.32,143.43c-1.35,15.74,10.36,29.64,26.09,30.99,.83,.07,1.64,.11,2.45,.11v0c14.74,0,27.28-11.51,28.54-26.19l12.32-143.43c1.35-15.74-10.35-29.64-26.09-30.99-.83-.07-1.64-.11-2.45-.11Z"/>
      </g>
      <g class="cls-3">
        <path class="cls-12" d="M271.49,563.88c-2.29,0-4.59-.2-6.88-.6-21.6-3.78-36.09-24.43-32.31-46.02l17.16-98.05c3.78-21.6,24.44-36.08,46.03-32.31h0c10.42,1.82,19.53,7.63,25.65,16.34,6.12,8.72,8.48,19.26,6.66,29.68l-17.16,98.05c-1.82,10.42-7.63,19.53-16.34,25.65-6.79,4.77-14.7,7.26-22.8,7.26Zm17.12-169.72c-15.2,0-28.7,10.93-31.41,26.41l-17.16,98.05c-3.03,17.33,8.6,33.89,25.93,36.92,8.35,1.46,16.81-.44,23.81-5.35,7-4.91,11.65-12.22,13.12-20.57l17.16-98.05c1.46-8.35-.44-16.81-5.35-23.81-4.91-7-12.22-11.65-20.57-13.12h0c-1.85-.32-3.7-.48-5.52-.48Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-5" d="M383.43,564.25c-8.41,0-16.59-2.68-23.53-7.79-8.57-6.32-14.17-15.56-15.75-26.02l-14.92-98.41c-1.59-10.46,1.02-20.95,7.33-29.52,6.32-8.57,15.56-14.17,26.02-15.75h0c10.45-1.59,20.95,1.02,29.52,7.33,8.57,6.32,14.17,15.56,15.75,26.02l14.92,98.41c3.29,21.68-11.68,41.99-33.36,45.27-2,.3-4.01,.45-5.99,.45Zm-19.67-169.72c-8.39,1.27-15.8,5.76-20.87,12.65-5.07,6.88-7.16,15.29-5.89,23.68l14.92,98.41c1.27,8.39,5.76,15.8,12.65,20.87,6.88,5.07,15.29,7.16,23.68,5.89,17.39-2.64,29.39-18.93,26.76-36.32l-14.92-98.41c-1.27-8.39-5.76-15.8-12.64-20.87-6.88-5.07-15.29-7.17-23.68-5.89h0Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-2" d="M532.6,447.25l-22.95-15.82c1.32-2.24,2.21-4.75,2.59-7.43,.18-.9,.24-1.85,.25-2.81,0-.95-.05-1.91-.21-2.81-.53-4.49-2.53-8.71-5.76-11.99-3.85-3.95-9.05-6.18-14.61-6.27h-.17c-5.5-.04-10.68,2.06-14.63,5.91-4.01,3.9-6.23,9.11-6.27,14.66-.13,11.51,9.07,20.94,20.57,21.08h.11c5.5,.03,10.6-2.1,14.35-5.65l24.86,17.13h104.18v-6h-102.31Zm-41.04-11.1h-.11c-8.36-.11-15.11-7-15-15.36,.03-4.1,1.68-7.91,4.56-10.75,2.88-2.79,6.71-4.33,10.75-4.31h.06c4.04,.03,7.85,1.68,10.69,4.56,2.17,2.26,3.61,5.02,4.16,8,.1,.9,.21,1.86,.2,2.81,0,.95-.12,1.91-.3,2.8-1.39,7.01-7.66,12.3-15.01,12.25Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-2" d="M482.26,519.25c-.55-4.44-2.54-8.61-5.74-11.86-3.85-3.95-9.05-6.18-14.61-6.27h-.17c-5.5-.04-10.68,2.06-14.63,5.91-4.01,3.9-6.23,9.11-6.27,14.66-.13,11.51,9.07,20.94,20.57,21.08h.11c5.67,.03,10.91-2.23,14.69-5.97,3.16-3.06,5.3-7.05,5.99-11.55h152.71v-6h-152.65Zm-5.69,5.65c-1.39,7.01-7.66,12.3-15.01,12.25h-.11c-8.36-.11-15.11-7-15-15.36,.03-4.1,1.68-7.91,4.56-10.75,2.88-2.79,6.71-4.33,10.75-4.31h.06c4.04,.03,7.85,1.68,10.69,4.56,2.17,2.26,3.61,5.02,4.16,8,.1,.9,.21,1.86,.2,2.81,0,.95-.12,1.91-.3,2.8Z"/>
      </g>
      <g class="cls-9">
        <path class="cls-2" d="M633.91,296.25v-6h-114.1v37.22c-4.01,.73-7.71,2.65-10.7,5.56-4.01,3.9-6.23,9.11-6.27,14.66-.13,11.51,9.07,20.94,20.57,21.08h.11c5.67,.03,10.91-2.23,14.69-5.97,3.22-3.12,5.38-7.2,6.03-11.8,.18-.9,.24-1.85,.25-2.81,0-.95-.05-1.91-.21-2.81-.53-4.49-2.53-8.71-5.76-11.99-3.41-3.5-7.88-5.65-12.71-6.15v-30.99h108.1Zm-99.4,41.04c2.17,2.26,3.61,5.02,4.16,8,.1,.9,.21,1.86,.2,2.81,0,.95-.12,1.91-.3,2.8-1.39,7.01-7.66,12.3-15.01,12.25h-.11c-8.36-.11-15.11-7-15-15.36,.03-4.1,1.68-7.91,4.56-10.75,2.88-2.79,6.71-4.33,10.75-4.31h.06c4.04,.03,7.85,1.68,10.69,4.56Z"/>
      </g>
      <g class="cls-1">
        <path class="cls-13" d="M135.22,176.98c-3.79-3.85-8.85-6.01-14.3-6.1h-.25c-2.77,0-5.47,.56-7.95,1.63l-10.76-10.76H1v7H99.06l7.71,7.71c-.06,.04-.12,.1-.18,.16-3.84,3.8-6,8.85-6.09,14.31-.12,9.16,6.16,17.32,14.76,19.67,.7,.17,1.41,.34,2.18,.45,1.03,.23,2.01,.29,3.05,.29h.22c.16,.01,.32,.01,.48,.01,5.02,0,9.96-2.13,13.68-5.75,3.84-3.79,6-8.85,6.09-14.24,.09-5.4-2-10.49-5.74-14.38Zm-4.2,24.73c-2.74,2.65-6.35,4.15-10.23,4.18h-.16c-.76-.06-1.42-.06-2.23-.18-1.09-.17-2.07-.45-3.05-.89-5.55-2.16-9.44-7.59-9.4-13.75v-.11c.03-3.98,1.63-7.68,4.43-10.44,2.71-2.62,6.23-4.2,9.89-4.2,.2,0,.41,0,.61,.02,3.93,.02,7.63,1.63,10.44,4.48,2.76,2.8,4.21,6.52,4.19,10.5-.03,3.93-1.64,7.62-4.49,10.39Z"/>
      </g>
      <g class="cls-1">
        <path class="cls-13" d="M174.22,230.98c-3.79-3.85-8.85-6.01-14.3-6.1h-.25c-5.29,0-10.32,2.04-14.08,5.74-3.08,3.05-5.08,6.9-5.79,11.13H0v7H139.82c1.39,7.53,7.06,13.83,14.44,15.85,.7,.17,1.41,.34,2.18,.45,1.03,.23,2.01,.29,3.05,.29h.22c.16,.01,.32,.01,.48,.01,5.02,0,9.96-2.13,13.68-5.75,3.84-3.79,6-8.85,6.09-14.24,.09-5.4-2-10.49-5.74-14.38Zm-4.2,24.73c-2.74,2.65-6.35,4.15-10.23,4.18h-.16c-.76-.06-1.42-.06-2.23-.18-1.09-.17-2.07-.45-3.05-.89-5.55-2.16-9.44-7.59-9.4-13.75v-.11c.03-3.98,1.63-7.68,4.43-10.44,2.71-2.62,6.23-4.2,9.89-4.2,.2,0,.41,0,.61,.02,3.93,.02,7.63,1.63,10.44,4.48,2.76,2.8,4.21,6.52,4.19,10.5-.03,3.93-1.64,7.62-4.49,10.39Z"/>
      </g>
      <g class="cls-1">
        <path class="cls-13" d="M172.22,300.98c-3.79-3.85-8.85-6.01-14.3-6.1h-.25c-5.29,0-10.32,2.04-14.08,5.74-3.84,3.8-6,8.85-6.09,14.31-.09,6.6,3.15,12.68,8.18,16.44l-10.11,20.88H.91v7H139.96l11.98-24.74c.1,.03,.21,.06,.32,.09,.7,.17,1.41,.34,2.18,.45,1.03,.23,2.01,.29,3.05,.29h.22c.16,.01,.32,.01,.48,.01,5.02,0,9.96-2.13,13.68-5.75,3.84-3.79,6-8.85,6.09-14.24,.09-5.4-2-10.49-5.74-14.38Zm-4.2,24.73c-2.74,2.65-6.35,4.15-10.23,4.18h-.16c-.76-.06-1.42-.06-2.23-.18-1.09-.17-2.07-.45-3.05-.89-5.55-2.16-9.44-7.59-9.4-13.75v-.11c.03-3.98,1.63-7.68,4.43-10.44,2.71-2.62,6.23-4.2,9.89-4.2,.2,0,.41,0,.61,.02,3.93,.02,7.63,1.63,10.44,4.48,2.76,2.8,4.21,6.52,4.19,10.5-.03,3.93-1.64,7.62-4.49,10.39Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-12" d="M206.26,534.96h-.24c-3.25,0-6.35,.76-9.11,2.14l-17.07-18.16H1v6H177.25l14.68,15.63c-.1,.09-.2,.19-.29,.28-3.19,3.09-5.32,7.12-5.96,11.67-.17,.88-.23,1.82-.24,2.77-.01,.94,.04,1.88,.2,2.77,.53,4.44,2.5,8.62,5.69,11.86,3.81,3.9,8.95,6.1,14.44,6.19h.3c5.39,0,10.45-2.07,14.33-5.84,3.96-3.85,6.16-9,6.2-14.49,.13-11.37-8.97-20.69-20.34-20.82Zm10.28,31.35c-2.82,2.73-6.56,4.26-10.52,4.26h-.16c-3.99-.03-7.75-1.66-10.56-4.51-2.15-2.23-3.57-4.96-4.11-7.91-.11-.88-.21-1.83-.2-2.77,0-.94,.12-1.89,.29-2.77,1.37-6.89,7.51-12.11,14.74-12.11h.21c8.26,.11,14.93,6.92,14.82,15.19-.03,4.05-1.66,7.81-4.51,10.62Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-12" d="M180.26,623.96h-.24c-5.55,0-10.67,2.23-14.38,5.89-3.19,3.09-5.32,7.12-5.96,11.67-.17,.88-.23,1.82-.24,2.77-.01,.94,.04,1.88,.2,2.77,.53,4.44,2.5,8.62,5.69,11.86,3.15,3.22,7.21,5.29,11.63,5.95v73.38h6v-73.35c4.29-.6,8.26-2.54,11.44-5.63,3.96-3.85,6.16-9,6.2-14.49,.13-11.37-8.97-20.69-20.34-20.82Zm10.28,31.35c-2.82,2.73-6.56,4.26-10.52,4.26h-.16c-3.99-.03-7.75-1.66-10.56-4.51-2.15-2.23-3.57-4.96-4.11-7.91-.11-.88-.21-1.83-.2-2.77,0-.94,.12-1.89,.29-2.77,1.37-6.89,7.51-12.11,14.74-12.11h.21c8.26,.11,14.93,6.92,14.82,15.19-.03,4.05-1.66,7.81-4.51,10.62Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-12" d="M298.26,608.96h-.24c-5.55,0-10.67,2.23-14.38,5.89-3.19,3.09-5.32,7.12-5.96,11.67-.17,.88-.23,1.82-.24,2.77-.01,.94,.04,1.88,.2,2.77,.49,4.13,2.23,8.04,5.04,11.17l-30.77,30.78v64.24h6v-61.76l29.4-29.4c3.11,1.91,6.71,2.96,10.46,3.02h.3c5.39,0,10.45-2.07,14.33-5.84,3.96-3.85,6.16-9,6.2-14.49,.13-11.37-8.97-20.69-20.34-20.82Zm10.28,31.35c-2.82,2.73-6.56,4.26-10.52,4.26h-.16c-3.99-.03-7.75-1.66-10.56-4.51-2.15-2.23-3.57-4.96-4.11-7.91-.11-.88-.21-1.83-.2-2.77,0-.94,.12-1.89,.29-2.77,1.37-6.89,7.51-12.11,14.74-12.11h.21c8.26,.11,14.93,6.92,14.82,15.19-.03,4.05-1.66,7.81-4.51,10.62Z"/>
      </g>
      <g class="cls-7">
        <path class="cls-2" d="M471.26,596.96h-.24c-5.55,0-10.67,2.23-14.38,5.89-3.13,3.03-5.24,6.96-5.92,11.4h-53.81v125h6v-119h47.76c.56,4.37,2.52,8.48,5.66,11.67,3.81,3.9,8.95,6.1,14.44,6.19h.3c5.39,0,10.45-2.07,14.33-5.84,3.96-3.85,6.16-9,6.2-14.49,.13-11.37-8.97-20.69-20.34-20.82Zm10.28,31.35c-2.82,2.73-6.56,4.26-10.52,4.26h-.16c-3.99-.03-7.75-1.66-10.56-4.51-2.15-2.23-3.57-4.96-4.11-7.91-.11-.88-.21-1.83-.2-2.77,0-.94,.12-1.89,.29-2.77,1.37-6.89,7.51-12.11,14.74-12.11h.21c8.26,.11,14.93,6.92,14.82,15.19-.03,4.05-1.66,7.81-4.51,10.62Z"/>
      </g>
      <g class="cls-1">
        <polygon class="cls-13" points="73.94 84.39 47.21 84.39 47.21 0 53.21 0 53.21 78.39 73.94 78.39 73.94 84.39"/>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 360px;
}

.cls-1 {
  opacity: .7;
}

.cls-2 {
  fill: #d32a5a;
}

.cls-3 {
  filter: url(#outer-glow-3);
}

.cls-4 {
  filter: url(#outer-glow-4);
}

.cls-5 {
  fill: #ea476e;
}

.cls-6 {
  filter: url(#outer-glow-1);
}

.cls-7 {
  opacity: .4;
}

.cls-8 {
  fill: #ef680c;
}

.cls-9 {
  opacity: .53;
}

.cls-10 {
  fill: #141414;
}

.cls-11 {
  fill: #ff9f1c;
}

.cls-12 {
  fill: #7c386d;
}

.cls-13 {
  fill: #00afb9;
}

.cls-14 {
  filter: url(#outer-glow-2);
}

.cls-15 {
  opacity: .71;
}
</style>
