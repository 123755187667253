<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.85 70">
  <defs>
  </defs>
  <g id="Layer_2-2" data-name="Layer 2">
    <g>
      <path class="cls-1" d="M76.49,27.78c-2.11,12.1-9.83,19.26-19.11,27.31-4.45,3.86-11.55,9.69-16.71,14.08-1.3,1.1-3.2,1.1-4.5,0-5.16-4.39-12.27-10.22-16.72-14.08-.32-.29-.65-.56-.97-.85-.24-.2-.47-.41-.7-.61-2.92-2.57-5.66-5.08-8.07-7.73-.06-.07-.13-.14-.19-.21-.15-.16-.29-.31-.43-.47-.02-.02-.04-.04-.06-.06C4.77,40.28,1.59,34.88,.35,27.78,.02,25.88-.07,23.94,.06,22.02c.02-.31,.05-.62,.07-.93,0-.05,0-.1,.02-.15,0-.06,0-.12,.02-.19,.02-.14,.04-.27,.05-.41,0-.02,0-.04,0-.06H.22c.04-.3,.08-.58,.13-.87C1.55,12.27,5.74,5.6,12.12,2.22c8.86-4.69,21.33-1.77,26.3,6.93,2.64-4.61,7.37-7.6,12.56-8.68,.17-.05,.34-.07,.51-.1,2.6-.48,5.29-.5,7.88,0,.1,.02,.2,.04,.3,.06,.07,.02,.15,.03,.22,.05,.23,.05,.47,.1,.7,.17,1.42,.36,2.81,.89,4.12,1.58,8.86,4.69,13.49,15.68,11.77,25.55Z"/>
      <path class="cls-2" d="M46.72,13.28c-.6,0-1.17-.23-1.6-.64-.92-.88-.95-2.35-.07-3.27,.43-.45,1-.7,1.62-.71,.59-.01,1.2,.22,1.65,.64,.92,.88,.95,2.35,.07,3.27h0c-.43,.45-1,.7-1.62,.71h-.05Zm0-3.7c-.37,0-.73,.14-1,.43-.53,.55-.51,1.43,.04,1.96,.55,.53,1.43,.51,1.96-.04h0c.53-.55,.51-1.43-.04-1.96-.27-.26-.61-.39-.96-.39Z"/>
      <path class="cls-2" d="M65.23,11.43c-.6,0-1.17-.23-1.6-.64-.92-.88-.95-2.35-.07-3.27,.43-.45,1-.7,1.62-.71,.59,0,1.2,.22,1.65,.64,.92,.88,.95,2.35,.07,3.27h0c-.43,.45-1,.7-1.62,.71h-.05Zm0-3.7c-.37,0-.73,.14-1,.43-.53,.55-.51,1.43,.04,1.96,.55,.53,1.43,.51,1.96-.04h0c.53-.55,.51-1.43-.04-1.96-.27-.26-.61-.39-.96-.39Z"/>
      <path class="cls-2" d="M25.44,11.43c-.6,0-1.17-.23-1.6-.64-.92-.88-.95-2.35-.07-3.27,.43-.45,1-.7,1.62-.71,.59,0,1.2,.22,1.65,.64,.92,.88,.95,2.35,.07,3.27h0c-.43,.45-1,.7-1.62,.71h-.05Zm0-3.7c-.37,0-.73,.14-1,.43-.53,.55-.51,1.43,.04,1.96,.55,.53,1.43,.51,1.96-.04h0c.53-.55,.51-1.43-.04-1.96-.27-.26-.61-.39-.96-.39Z"/>
      <path class="cls-2" d="M21.74,16.98c-.6,0-1.17-.23-1.6-.64-.92-.88-.95-2.35-.07-3.27,.43-.45,1-.7,1.62-.71,.59-.02,1.2,.22,1.65,.64,.92,.88,.95,2.35,.07,3.27h0c-.43,.45-1,.7-1.62,.71h-.05Zm0-3.7c-.37,0-.73,.14-1,.43-.53,.55-.51,1.43,.04,1.96,.55,.53,1.43,.51,1.96-.04h0c.53-.55,.51-1.43-.04-1.96-.27-.26-.61-.39-.96-.39Z"/>
      <path class="cls-2" d="M10.04,17.91c-.6,0-1.17-.23-1.6-.64-.92-.88-.95-2.35-.07-3.27,.43-.45,1-.7,1.62-.71,.6-.02,1.2,.22,1.65,.64,.92,.88,.95,2.35,.07,3.27h0c-.43,.45-1,.7-1.62,.71h-.05Zm0-3.7c-.37,0-.73,.14-1,.43-.53,.55-.51,1.43,.04,1.96,.55,.53,1.43,.51,1.96-.04h0c.53-.55,.51-1.43-.04-1.96-.27-.26-.61-.39-.96-.39Z"/>
      <polygon class="cls-2" points="20.07 14.66 15.91 12.24 10.18 3.54 10.95 3.03 16.57 11.55 20.53 13.86 20.07 14.66"/>
      <rect class="cls-2" x="23.75" y="3.76" width="6.6" height=".93" transform="translate(14.99 28.81) rotate(-72.73)"/>
      <path class="cls-2" d="M57.36,38.26c-2.88,0-5.6-1.09-7.68-3.09-2.14-2.05-3.35-4.82-3.41-7.78-.06-2.96,1.04-5.78,3.09-7.92s4.82-3.35,7.78-3.41c2.97-.08,5.78,1.04,7.92,3.09,2.14,2.05,3.35,4.82,3.41,7.78,.06,2.97-1.04,5.78-3.09,7.92-2.05,2.14-4.82,3.35-7.78,3.41-.08,0-.16,0-.24,0Zm.01-19.43c-.06,0-.12,0-.18,0-2.22,.05-4.3,.96-5.84,2.56-1.54,1.61-2.36,3.71-2.32,5.94,.05,2.22,.96,4.3,2.56,5.84,1.61,1.54,3.7,2.35,5.94,2.32,2.22-.05,4.3-.96,5.84-2.56,1.54-1.61,2.36-3.71,2.32-5.94-.05-2.22-.96-4.3-2.56-5.84-1.56-1.5-3.6-2.32-5.76-2.32Z"/>
      <g>
        <g>
          <path class="cls-2" d="M51.5,.37c-.17,.03-.34,.06-.51,.1v-.1h.51Z"/>
          <path class="cls-2" d="M51.92,.37V6.79l-3.55,3.56-.66-.65,3.28-3.29V.48c.17-.05,.34-.07,.51-.1h.42Z"/>
        </g>
        <polygon class="cls-2" points="51.5 .37 51.92 .3 51.92 .37 51.5 .37"/>
      </g>
      <polygon class="cls-2" points="10.87 2.95 10.11 3.45 10.18 3.54 10.95 3.03 10.87 2.95"/>
      <path class="cls-2" d="M38.2,35.59l-19.72,18.65c-.24-.2-.47-.41-.7-.61l19.79-18.72,.63,.68Z"/>
      <path class="cls-2" d="M23.61,32.95l-13.9,12.94c-.06-.07-.13-.14-.19-.21-.15-.16-.29-.31-.43-.47l13.2-12.31-3.5-3.69,.68-.64,4.14,4.38Z"/>
      <path class="cls-2" d="M18.1,29.43c-.61,0-1.23-.24-1.68-.72-.42-.45-.65-1.04-.63-1.65,.02-.62,.27-1.19,.72-1.62s1.03-.67,1.65-.63c.62,.02,1.19,.27,1.62,.72,.42,.45,.65,1.04,.63,1.65-.02,.62-.27,1.19-.72,1.62h0c-.45,.42-1.02,.63-1.59,.63Zm0-3.7c-.36,0-.69,.13-.95,.38-.27,.25-.42,.6-.43,.97,0,.37,.12,.72,.38,.99,.53,.56,1.41,.58,1.96,.06,.27-.25,.42-.6,.43-.97,0-.37-.12-.72-.38-.99s-.6-.42-.97-.43c-.01,0-.03,0-.04,0Z"/>
      <path class="cls-2" d="M38.85,36.56s-.04,0-.07,0c-.62-.02-1.19-.27-1.62-.72-.42-.45-.65-1.04-.63-1.65,.02-.62,.27-1.19,.72-1.62,.45-.42,1.04-.63,1.65-.63,.62,.02,1.19,.27,1.62,.72,.88,.93,.84,2.39-.09,3.27-.43,.41-.99,.63-1.59,.63Zm0-3.7c-.36,0-.69,.13-.95,.38-.27,.25-.42,.6-.43,.97,0,.37,.12,.72,.38,.99s.6,.42,.97,.43c.36,.02,.72-.12,.99-.38h0c.56-.53,.58-1.41,.05-1.96-.25-.27-.6-.42-.97-.43-.01,0-.03,0-.04,0Z"/>
      <path class="cls-2" d="M29.05,35.63s-.04,0-.07,0c-.62-.02-1.19-.27-1.62-.72-.42-.45-.65-1.04-.63-1.65,.02-.62,.27-1.19,.72-1.62,.45-.42,1.04-.63,1.65-.63,.62,.02,1.19,.27,1.62,.72,.88,.93,.84,2.39-.09,3.27h0c-.43,.41-.99,.63-1.59,.63Zm0-3.7c-.36,0-.69,.13-.95,.38-.27,.25-.42,.6-.43,.97,0,.37,.12,.72,.38,.99s.6,.42,.97,.43c.37,.03,.72-.12,.99-.38h0c.27-.25,.42-.6,.43-.97s-.12-.72-.38-.99-.6-.42-.97-.43c-.01,0-.03,0-.04,0Z"/>
      <path class="cls-2" d="M45.8,52.14c-.85,0-1.67-.47-2.08-1.29-.27-.55-.32-1.18-.12-1.77s.61-1.06,1.16-1.33c1.14-.56,2.53-.1,3.1,1.05,.57,1.14,.1,2.53-1.05,3.1-.33,.16-.68,.24-1.02,.24Zm0-3.7c-.21,0-.42,.05-.61,.14-.33,.16-.58,.45-.7,.8s-.09,.73,.07,1.06c.34,.69,1.18,.97,1.86,.63,.68-.34,.97-1.17,.63-1.86-.24-.49-.73-.77-1.25-.77Z"/>
      <polygon class="cls-2" points="43.8 49.34 37.31 47.17 25.79 47.16 25.79 46.24 37.54 46.27 44.1 48.46 43.8 49.34"/>
      <polygon class="cls-2" points="30.33 42.4 24.88 36.63 27.39 34.25 28.03 34.92 26.19 36.67 31.01 41.76 30.33 42.4"/>
      <path class="cls-2" d="M63.57,8.65v.93h-3.9V.43c.07,.02,.15,.03,.22,.05,.23,.05,.47,.1,.7,.17V8.65h2.97Z"/>
      <path class="cls-2" d="M10.5,17.19v4.83H.06c.02-.31,.05-.62,.07-.93H9.58v-3.9h.93Z"/>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 40px;
}  

.cls-1 {
  fill: #ccc;
}

.cls-2 {
  fill: #7a7a7a;
}
</style>
