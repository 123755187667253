<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.96 80.58">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-1" d="M71.96,56.38V24.2c0-2.9-1.55-5.58-4.06-7.02L40.04,1.09c-2.51-1.45-5.6-1.45-8.11,0L4.06,17.18C1.55,18.63,0,21.3,0,24.2V56.38c0,2.9,1.55,5.58,4.06,7.02l27.87,16.09c2.51,1.45,5.6,1.45,8.11,0l27.87-16.09c2.51-1.45,4.06-4.13,4.06-7.02Z"/>
        <g>
          <g>
            <path class="cls-2" d="M51.22,34.51c0,.21,0,.39-.03,.54s-.05,.27-.08,.37c-.04,.09-.08,.16-.14,.2-.06,.04-.12,.06-.2,.06h-8.13c-.16,0-.3-.01-.41-.04-.12-.03-.21-.09-.28-.17s-.13-.21-.16-.37c-.03-.16-.05-.37-.05-.62,0-.24,.01-.45,.03-.62,.02-.17,.06-.32,.12-.46,.06-.13,.13-.27,.22-.39,.09-.13,.21-.27,.35-.42l2.45-2.62c.49-.51,.88-.97,1.18-1.39,.3-.42,.53-.8,.7-1.15s.28-.66,.34-.96c.06-.29,.09-.57,.09-.83,0-.24-.04-.47-.11-.68-.08-.21-.19-.4-.34-.56-.15-.16-.34-.29-.56-.38-.23-.09-.49-.14-.8-.14-.43,0-.81,.05-1.14,.16-.33,.11-.62,.23-.87,.37-.25,.13-.46,.26-.63,.37s-.3,.17-.39,.17c-.07,0-.12-.02-.17-.07s-.09-.12-.11-.22c-.03-.1-.05-.24-.07-.41-.02-.17-.03-.38-.03-.63,0-.17,0-.31,.02-.42,.01-.11,.03-.21,.05-.3,.02-.08,.05-.16,.09-.22,.04-.06,.1-.14,.19-.22s.26-.2,.5-.33,.54-.27,.9-.39c.36-.13,.75-.24,1.18-.32,.43-.09,.88-.13,1.34-.13,.74,0,1.38,.09,1.93,.28,.55,.19,1.01,.44,1.37,.78,.36,.33,.64,.73,.81,1.18,.18,.46,.27,.95,.27,1.47,0,.46-.04,.91-.13,1.35-.09,.44-.27,.92-.55,1.44-.28,.51-.67,1.08-1.17,1.7-.5,.62-1.17,1.35-2,2.17l-1.65,1.69h5.57c.07,0,.14,.02,.2,.07s.11,.11,.16,.2c.04,.09,.08,.21,.1,.36,.02,.15,.03,.33,.03,.53Z"/>
            <path class="cls-2" d="M62.43,31.09c0,.76-.13,1.45-.4,2.05-.27,.6-.64,1.11-1.13,1.52-.48,.41-1.07,.73-1.75,.95-.68,.22-1.44,.33-2.27,.33-.44,0-.85-.03-1.25-.08-.39-.05-.75-.12-1.06-.21-.31-.08-.57-.17-.77-.25-.2-.08-.33-.15-.39-.21-.06-.05-.11-.11-.14-.18s-.05-.14-.07-.23c-.02-.09-.03-.21-.04-.34,0-.14-.01-.3-.01-.49,0-.2,0-.38,.02-.52,.01-.14,.04-.26,.07-.34,.03-.09,.07-.15,.11-.19,.04-.04,.09-.05,.15-.05,.07,0,.18,.04,.33,.13s.35,.18,.59,.28c.24,.1,.54,.2,.89,.28,.35,.08,.77,.13,1.26,.13,.42,0,.81-.04,1.15-.13s.64-.23,.88-.42,.43-.44,.56-.73c.13-.29,.2-.64,.2-1.05,0-.35-.05-.66-.16-.93-.11-.27-.28-.5-.51-.69-.23-.19-.54-.33-.91-.43-.37-.09-.82-.14-1.35-.14-.42,0-.81,.02-1.15,.07-.35,.04-.67,.07-.98,.07-.21,0-.36-.05-.45-.16s-.14-.3-.14-.58v-6.14c0-.29,.06-.5,.17-.63,.11-.13,.29-.19,.54-.19h6.7c.07,0,.13,.02,.19,.07,.06,.04,.11,.11,.15,.21,.04,.09,.07,.22,.09,.38,.02,.16,.03,.34,.03,.56,0,.44-.04,.75-.11,.95-.08,.2-.19,.29-.33,.29h-5.03v3.03c.25-.03,.51-.05,.76-.05,.25,0,.51-.01,.79-.01,.77,0,1.44,.09,2.04,.27s1.09,.44,1.5,.79c.4,.35,.71,.78,.92,1.29,.21,.51,.32,1.11,.32,1.79Z"/>
          </g>
          <g>
            <path class="cls-2" d="M60.51,51.57c-1.22,0-2.2-.99-2.2-2.2s.99-2.2,2.2-2.2,2.2,.99,2.2,2.2-.99,2.2-2.2,2.2Zm0-3.31c-.61,0-1.1,.49-1.1,1.1s.49,1.1,1.1,1.1,1.1-.49,1.1-1.1-.49-1.1-1.1-1.1Z"/>
            <path class="cls-2" d="M57.2,42.76c-1.22,0-2.2-.99-2.2-2.2s.99-2.2,2.2-2.2,2.2,.99,2.2,2.2-.99,2.2-2.2,2.2Zm0-3.31c-.61,0-1.1,.49-1.1,1.1s.49,1.1,1.1,1.1,1.1-.49,1.1-1.1-.49-1.1-1.1-1.1Z"/>
            <path class="cls-2" d="M52.79,59.29c-1.22,0-2.2-.99-2.2-2.2s.99-2.2,2.2-2.2,2.2,.99,2.2,2.2-.99,2.2-2.2,2.2Zm0-3.31c-.61,0-1.1,.49-1.1,1.1s.49,1.1,1.1,1.1,1.1-.49,1.1-1.1-.49-1.1-1.1-1.1Z"/>
            <rect class="cls-2" x="41.74" y="40.18" width="13.81" height="1.1"/>
            <polygon class="cls-2" points="59.29 48.86 56.42 45.99 41.74 45.99 41.74 44.88 56.88 44.88 60.07 48.08 59.29 48.86"/>
            <polygon class="cls-2" points="53.31 55.43 52.21 55.43 52.21 51.45 41.74 51.45 41.74 50.35 53.31 50.35 53.31 55.43"/>
          </g>
          <path class="cls-2" d="M24.13,56.54c-8.21,0-14.88-6.68-14.88-14.88s6.68-14.88,14.88-14.88,14.88,6.68,14.88,14.88-6.68,14.88-14.88,14.88Zm0-24.25c-5.17,0-9.37,4.2-9.37,9.37s4.2,9.37,9.37,9.37,9.37-4.2,9.37-9.37-4.2-9.37-9.37-9.37Z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 40px;
}  

.cls-1 {
  fill: #ccc;
}

.cls-2 {
  fill: #7a7a7a;
}
</style>
