<template>
  <div class="select-service-container">
    <div class="select-body">
      <div class="dashboard-card select-card">
        <div class="image-container hidden-mobile">
          <CheckoutSelectServiceIcon />
        </div>

        <div class="form-container">
          <h4>Select a service</h4>
          <div class="mt-2">
            <div class="select-service-item"  @click="onChangeService('G25')"
              :class="{'selected': selectedService === 'G25'}">
              <select-g25-icon/>
              <span>
                GLOBAL25
              </span>
            </div>
            <div class="select-service-item"  @click="onChangeService('TRAITH-HEALTH')"
              :class="{'selected': selectedService === 'TRAITH-HEALTH'}">
              <SelectTraithHealthIcon/>
              <span>
                TRAITS & HEALTH
              </span>
            </div>
            <!-- <div class="select-service-item"  @click="onChangeService('IBD')"
              :class="{'selected': selectedService === 'IBD'}">
              <SelectIbdIcon/>
              <span>
                POPULATION-WISE IBD
              </span>
            </div> -->
          </div>
          <div class="step-buttons">
            <button class="lab-btn next valid"
              @click="next()">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { PRODUCT_CODES, TRAIT_CHECKOUT_STEPS, G25_CHECKOUT_STEPS } from '@/common/config';
import CheckoutSelectServiceIcon from '@/layouts/icons/store/CheckoutSelectServiceIcon.vue'
import SelectG25Icon from './icons/SelectG25Icon.vue';
import SelectTraithHealthIcon from './icons/SelectTraithHealthIcon.vue';

export default {
  components: {
    CheckoutSelectServiceIcon,

    SelectG25Icon,
    SelectTraithHealthIcon,
  },
  data() {
    return {
      PRODUCT_CODES,
      TRAIT_CHECKOUT_STEPS,

      selectedService: 'G25',
    }
  },
  methods: {
    onChangeService(service) {
      this.selectedService = service;
    },
    next() {
      if (this.selectedService === 'G25') {
        this.$router.push(`/checkout/g25/${G25_CHECKOUT_STEPS.SELECT_SERVICE}/`);
      } else if (this.selectedService === 'TRAITH-HEALTH') {
        this.$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`);
      }
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss">
@import "../purchase.scss";
</style>

<style lang="scss" scoped>
.select-service-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .select-body {
    padding: 2.5rem;
    max-width: 1020px;
  }
 .select-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    .image-container {
      margin-right: 1rem;
      padding: 2rem;
    }
    h4 {
      color: var(--lab-black);
      font-size: 1.5rem;
    }
  }

  .form-container {
    height: 340px;
    position: relative;
    
    .select-service-item {
      padding: 0.7rem 1.5rem;
      margin-bottom: 5px;
      cursor: pointer;
      transition: var(--lab-trans-04);

      span {
        color: var(--lab-carbon-fiber);
        font-weight: 500;
        margin-left: 1rem;
      }
    }
    .select-service-item:hover {
      background-color: var(--lab-light-house);
      border-radius: 10px;
    }
    .select-service-item.selected {
      background-color: var(--lab-light-house);
      border-radius: 10px;
    }

    .step-buttons {
      position: absolute;
      bottom: 2px;
      right: 2px;
    }
  }
}

</style>
